import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Table,
  Button,
  Dropdown,
  Tabs,
  Tab,
  Form,
  Modal,
  Accordion,
} from "react-bootstrap";
import icontransaction from "../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../Assets/img/admin-icons/icon-eye.png";
import iconcoin from "../Assets/img/admin-icons/icon-coins-1.png";
import { Notifications } from "../Components/Toast";
import {
  AllTransationShow,
  AllUserShow,
  BlockUSer,
  DownLoaduserList,
  GetUSerActivity,
  ReffrealId,
  TransationOfUser,
  unBlockUSer,
} from "../Api's";
import { FilterButtonColor, formatDate } from "../Constants";
import Loader from "./Loader";
import Paginationnav from "../Components/Pagination";
import Pagination from "../Components/Pagination";
import moment from "moment";
import UserTableList from "./UserTablelist";
// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       className="admin-modal"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Body>
//         <Button className="close-btn" onClick={props.onHide}><i className="fa fa-close"></i></Button>
//         <h6>
//           Login Activities <i className="fa fa-angle-right"></i>{" "}
//           <span>UD09525</span>
//         </h6>
//         <div className="text-info">
//           <p className="m-0">Login with Chrome in OS X-10_15_7 via 172.70.55.74</p>
//           <p className="m-0">04 Jan, 2024 12:16 AM</p>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }

function UserList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setselectedFilter] = useState();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [referralModal, setreferralModal] = useState(false);
  const [ReferralData, setReferralData] = useState([]);
  const [SelectedAccountStatus ,setSelectedAccountStatus  ] =useState('all')

  const [activityModal, setactivityModal] = useState(false);
  const [activityData, setactivityData] = useState([]);
  const [selectedSearchFilter , setselectedSearchFilter ] = useState('name')
  const [SelectedBalanceFilter,setSelectedBalanceFilter] = useState('all')
  const [SelectedReferredFilter,setSelectedReferredFilter] =useState('all')
  const [SelectedRegMethod,setSelectedRegMethod]=useState('all')
  const [SelctedRefferdBy,setSelctedRefferdBy]=useState()

  const [activeTab, setActiveTab] = useState("Investor / Users");
  const filterStatus = {
    Approved: "Approved",
    pending: "Pending",
    Expired: "Expired",
  };
  const filter = {
    Purchase: "Purchase",
    Bonuses: "Bonus",
    Referral: "Referral",
    All: "All",
  };
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const itemsPerPage = 5;
  const SetAllUsers = async (page, selectedFilter) => {
  
    try {
      setIsLoading({ transactions: true });
      
      let obj = {
        
                hasWallet : hasWallet?'true':'false',
                isReferred : SelectedReferredFilter,
                hasToken : SelectedBalanceFilter,
                type : SelectedRegMethod,
                isblocked : SelectedAccountStatus,   
              }

        if(selectedSearchFilter === FilterType?.name)  
        {
                 obj.name =    selectedSearchFilter
        }    
      

     else   if(selectedSearchFilter === FilterType?.email)  
        {
                 obj.email =    selectedSearchFilter
        }    
      

     else   if(selectedSearchFilter === FilterType?.id)  
        {
                 obj.id =    selectedSearchFilter
        }    
      

       else if(selectedSearchFilter === FilterType?.walletAddress)  
        {
                 obj.walletAddress =    selectedSearchFilter
        }    
      
      const response = await AllUserShow(
        itemsPerPage,
        page,
        selectedFilter,
        searchTerm,
        obj

      );
      const alluser = response?.data || [];
      setTransactions(alluser);

      setTotalItems(response?.totalItems?.sum);
      // setError(false);
    } catch (error) {
      Notifications("error", "not found");
      setError(false);
      // setError(true);
    } finally {
      setIsLoading({ transactions: false });
      setError(true);
    }
  };
  useEffect(() => {
    SetAllUsers(1, selectedFilter);
    setCurrentPage(1);
  }, []);
  const handlePageChange = (page) => {
    SetAllUsers(page, selectedFilter);
    setCurrentPage(page);
  };
  // const filteredTransactions = transactions?.filter(transaction =>
  //   transaction?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  // );
  const handleOpenModal = (selectedId) => {
    setId(selectedId);
    setShow(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading({ data: true });
        const response = await TransationOfUser(id);
        const transdata = response?.data || [];
        setData(transdata);
      } catch (error) {
      } finally {
        setIsLoading({ data: false });
        setError(true);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const GetReffrealId = (id) => {
    ReffrealId(id)
      .then((data) => {
        const reffrel = data || [];
        setReferralData(reffrel?.data);
      })
      .catch(() => {});
  };

  const GetUSerActivityfun = (id) => {
    GetUSerActivity(id)
      .then((data) => {
        const reffrel = data || [];
        setactivityData(reffrel?.data);
      })
      .catch(() => {});
  };
  const BlockUserCall = (id) => {
    BlockUSer(id)
      .then(() => {
        SetAllUsers(currentPage, selectedFilter);
        Notifications("success", "user blocked successfully");
      })
      .catch(() => {});
  };
  const UnBlockUserCall = (id) => {
    unBlockUSer(id)
      .then(() => {
        SetAllUsers(currentPage, selectedFilter);
        Notifications("success", "user Unblocked successfully");
      })
      .catch(() => {});
  };

  const TransactionStatus = {
    APPROVED: "approved",
    PENDING: "pending",
  };

  const TransactionType = {
    BONUS: "Bonus",
  };
  // State to track the active status
  const [isActive, setIsActive] = useState(false);

  // Function to handle button click and toggle active status
  const handleButtonClick = () => {
    setIsActive(!isActive);
  };
  
  const FilterType = {
    name : 'name',
    email :  'email',
    id : 'id',
    walletAddress : 'walletAddress'
  }
  const AccountStatus = {
    Active : 'true',
    DeActive :  'false',
    All : 'all',
  }
  const RegMethod = {
    any : 'any',
    email : 'mail',
    wallet :  'walletaddress',
  }
  const TokenBalance ={
    AnyBalance :'all',
    hasToken:'true',
    ZeroToken :'false'
  }

  const ReferredBy ={
    anyReferred :'any',
    isReferred:'true',
    notReferred :'false'
  }

  const Search = ()=>{
    SetAllUsers(1,selectedFilter)
  }

  

  
  const [hasWallet,setHasWallet] = useState(false)
  return (
    <>
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="white-post">
                  <div className="flex-div-xs align-items-center">
                    <h4 className="color font-weight-bolder">
                      Investor User List
                    </h4>
                    <div className="d-flex">
                      <Button
                        className={`reg-btn smally purple mr-3 ${
                          isActive ? "active" : ""
                        }`}
                        onClick={handleButtonClick}
                      >
                        <i className="fa fa-sliders"></i>
                      </Button>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={iconcoin} alt="Coin" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item  
                          onClick={()=>{
                            DownLoaduserList().then(()=>{

                            }).catch(()=>{

                            })
                          }}
                          href="#/action-1">
                            Export
                          </Dropdown.Item>
                      
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className={`setting-tab ${isActive ? "active" : ""}`}>
                    <Form>
                      <Row>
                        <Col xxl="5" xl="6" lg="6" md="12" sm="12">
                          <div className="advance-seach-pnl">
                            <input
                              type="text"
                              placeholder="Search by Email/Wallet Address"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              className="input-solid input-transparent"
                            />
                            <div className="d-flex">
                              <Button 
                               style={selectedSearchFilter === 
                                FilterType?.name ?
                                {backgroundColor:FilterButtonColor}:{}}
                              onClick={()=>{

                                setselectedSearchFilter(FilterType?.name)
                              }}  >Name</Button>
                              <Button 
                                   style={selectedSearchFilter === 
                                    FilterType?.email ?
                                    {backgroundColor:FilterButtonColor}:{}}
                               onClick={()=>{
                                setselectedSearchFilter(FilterType?.email)
                              }}
                              >Email</Button>
                              <Button  
                                   style={selectedSearchFilter === 
                                    FilterType?.id ?
                                    {backgroundColor:FilterButtonColor}:{}}
                               onClick={()=>{
                                setselectedSearchFilter(FilterType?.id)
                              }}
                              >ID</Button>
                              <Button  
                                   style={selectedSearchFilter === 
                                    FilterType?.walletAddress ?
                                    {backgroundColor:FilterButtonColor}:{}}
                               onClick={()=>{
                                setselectedSearchFilter(FilterType?.walletAddress)
                              }}
                              >Wallet</Button>
                            </div>
                          </div>
                          <div className="spacer-20"></div>
                        </Col>
                        <Col xxl="5" xl="5" lg="6" md="12" sm="12">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  value={hasWallet}
                                  type={type}
                                   onChange={(e)=>setHasWallet(!hasWallet)}
                                  id={`default-${type}`}
                                  label={`Has Wallet`}
                                />

                                {/* <Form.Check
                                  inline
                                  type={type}
                                  label={`disabled ${type}`}
                                  id={`Including Admin`}
                                /> */}
                              </div>
                            ))}
                          </Form>
                          <div className="spacer-20"></div>
                        </Col>
                        <Col xl="12" lg="12" md="12" sm="12"></Col>
                        <Col xl="2" lg="2" md="4" sm="6">
                          <Form.Group
                            className="mb-3"
                            
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Account Status</Form.Label>
                            <Form.Select
                            value={SelectedAccountStatus}
                            onChange={(e)=>{setSelectedAccountStatus(e.target.value)}}
                            aria-label="Default select example">
                              <option  value={AccountStatus.All}>Any Status</option>
                              <option value={AccountStatus.DeActive}> Active</option>
                              <option value={AccountStatus.Active}> Suspended</option>
                        
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="4" sm="6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Reg Method</Form.Label>
                            <Form.Select 
                            value={SelectedRegMethod}
                            onChange={(e)=> setSelectedRegMethod(e.target.value ) }
                            aria-label="Default select example">
                              <option  value={RegMethod?.any} >Any Method</option>
                              <option  value={RegMethod?.wallet} >Wallet Address</option>
                              <option  value={RegMethod?.email} >Email</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="4" sm="6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Vefrified Status</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Verified</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="4" sm="6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Token Balance</Form.Label>
                            <Form.Select  
                              value={SelectedBalanceFilter}
                              onChange={(e)=>setSelectedBalanceFilter(e.target.value)}
                            aria-label="Default select example">
                              <option value={TokenBalance?.AnyBalance}>Any Amount</option>
                              <option value={TokenBalance?.ZeroToken}>Zero Token</option>
                              <option value={TokenBalance?.hasToken}>Has Token</option>
                        
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="4" sm="6">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Is Referred By</Form.Label>
                            <Form.Select  
                            value={SelectedReferredFilter}
                              onChange={(e)=>{
                                setSelectedReferredFilter(e.target.value)
                              }}
                            aria-label="Default select example">
                              <option value={ReferredBy?.anyReferred }>Any</option>
                              <option value={ReferredBy?.isReferred }>Yes</option>
                              <option value={ReferredBy?.notReferred }>No</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="4" sm="6">
                          <Button  
                          onClick={()=>{
                            Search()
                          }}  
                          className="reg-btn smally purple">
                            <i className="fa fa-search"></i> Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="spacer-30"></div>
                  <div className="input-trans-container">
                    {/* <div className="input-wrap">
                      <span class="input-icon input-icon-left">
                        {" "}
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Search by Email/Wallet Address"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="input-solid input-transparent"
                      />
                    </div> */}
                    <Tabs
                      defaultActiveKey="USERS"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      onSelect={(e) => {
                        setselectedFilter(e);
                        // alert(filter[e])
                      }}
                    >
                      <Tab eventKey="USERS" title="USERS">
                        <div className="table-container">
                          <div className="table-container-inner">
                            <Table className="transaction-table">
                              <thead>
                                <tr>
                                  <th>USER</th>
                                  <th>EMAIL</th>
                                  <th>Total Token</th>
                                  <th>VERIFIED STATUS</th>
                               
                                  {/* <th>LAST LOGIN</th> */}
                                  <th>STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isLoading?.transactions ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <Loader animation="border" />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {transactions?.length ? (
                                      transactions.map((admin, index) => (
                                    <UserTableList admin={admin} index={index}  handleOpenModal={handleOpenModal} GetUSerActivityfun={GetUSerActivityfun} setactivityModal={setactivityModal} GetReffrealId={GetReffrealId} setreferralModal={setreferralModal} BlockUserCall={BlockUserCall}  UnBlockUserCall={UnBlockUserCall}/>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No Users available.
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>
                      {/* 
                    <Tab eventKey="USERS" title="USERS">
                      <div className="table-container">
                        <div className="table-container-inner">
                          <Table className="transaction-table">
                            <thead>
                              <tr>
                                <th>USER</th>
                                <th>EMAIL</th>
                                <th>TOKENS</th>
                                <th>VERIFIED STATUS</th>
                                <th>STATUS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading?.transactions ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    <Loader animation="border" />
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  {transactions?.length ? (
                                    transactions.map((user, index) => (
                                      <tr key={index}>
                                        <td className="d-flex">
                                          <div>
                                            <p className="m-0 font-weight-bold">
                                              {user?.name}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <p className="m-0 font-weight-bold">
                                            {user?.email}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="m-0 font-weight-bold">
                                            01
                                          </p>
                                        </td>
                                        <td>
                                          <p className="m-0 font-weight-bold">
                                            <span className="clock-span">
                                              <i className="fa fa-check"></i>
                                            </span>{" "}
                                            Email {""}
                                         
                                          </p>
                                        </td>
                                      
                                        <td>

                                        <div className="d-flex">
                                            <Button className="reg-btn trans small mr-2">
                                              Active
                                            </Button>
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                              >
                                                <i className="fa fa-ellipsis-h "></i>
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item >
                                                <Button 
                                                     
                                                      onClick={()=>{
                                                  
                                                        navigate(`/UserListDetails/${user?.id}`, { state: { transactionObject: user } });
                                                      }}
                                                        className="view"
                                                   
                                                      >
                                                        <i className="fa fa-eye"></i>{" "}
                                                        View Details
                                                      </Button>
                                             
                                                </Dropdown.Item>
                                             
                                               
                                                <Dropdown.Item href="#/action-1">
                                                <i class="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                                                Send Email
                                                </Dropdown.Item>
                                                <Dropdown.Item     onClick={() => handleOpenModal(user?.id)}>
                                                  <i className="fa fa-random"></i>{" "}
                                                  Transaction
                                                </Dropdown.Item>
                                                <Dropdown.Item     onClick={() => setModalShow(true)}>
                                                  <i className="fa fa-sign-out"></i>{" "}
                                                  Activities
                                                </Dropdown.Item>
                                                <Dropdown.Item     onClick={() => setModalShow(true)}>
                                                  <i className="fa fa-users"></i>{" "}
                                                  Referrals
                                                </Dropdown.Item>
                                             
                                                <Dropdown.Item href="#/action-2">
                                                  <i className="fa fa-ban"></i>{" "}
                                                  Suspend
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </td>
                                      
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="6" className="text-center">
                                        No transactions available.
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <div className="spacer-20"></div>
                    </Tab> */}
                    </Tabs>
                  </div>
                  {transactions?.length > 0 && (
                    <div className="text-center">
                      <Pagination
                        currentPage1={currentPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPageChange={(page) => handlePageChange(page)}
                      />
                    </div>
                  )}
                  <div className="spacer-10"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
        <Modal
          show={show}
          centered
          onHide={handleClose}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          size="xl"
          className="admin-modal"
        >
          <Modal.Body>
            <Button className="close-btn" onClick={handleClose}>
              <i className="fa fa-close"></i>
            </Button>
            <>
              <h6>
                Transactions Activities <i className="fa fa-angle-right"></i>{" "}
                {/* <span>UD09525</span> */}
              </h6>
              {/* <div className="text-info">
                <p className="m-0">Login with Chrome in OS X-10_15_7 via 172.70.55.74</p>
                <p className="m-0">04 Jan, 2024 12:16 AM</p>
            </div> */}
              <div className="table-container">
                <div className="table-container-inner">
                  <Table className="transaction-table">
                    <thead>
                      <tr>
                        <th>TRANX ID</th>
                        <th>TOKENS</th>
                        <th>AMOUNT</th>
                        <th>BASE AMOUNT</th>
                        <th>PAY FROM</th>
                        <th>PAYMENT ID</th>
                        <th className="text-right">TYPE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading?.data ? (
                        <tr>
                          <td colSpan="6" className="text-center">
                            <Loader animation="border" />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {data?.length ? (
                            data.map((transaction, index) => (
                              <tr key={index}>
                                <td className="d-flex">
                                  {/* <span className="clock-span">
  {transaction?.status === TransactionStatus.APPROVED ||
    transaction?.type === TransactionType.BONUS ? (
    <i className="fa fa-check"></i>
  ) : (
    <i className="fa fa-clock-o"></i>
  )}
</span> */}{" "}
                                  <span className="clock-span">
                                    {transaction?.status === "Approved" ? (
                                      <i className="fa fa-check"></i>
                                    ) : transaction?.status === "Rejected" ? (
                                      <i className="fa fa-times"></i>
                                    ) : (
                                      <i className="fa fa-clock-o"></i>
                                    )}
                                  </span>
                                  <div>
                                    <p className="m-0 font-weight-bold">
                                      {transaction?.transaction_number}
                                    </p>
                                    <span>
                                      {
                                        formatDate(
                                          transaction?.created_at
                                        ).split(", ")[0]
                                      }
                                      ,{""}
                                      {
                                        formatDate(
                                          transaction?.created_at
                                        ).split(", ")[1]
                                      }{" "}
                                      {""}
                                      {
                                        formatDate(
                                          transaction?.created_at
                                        ).split(", ")[2]
                                      }
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <p className="m-0 font-weight-bold">
                                    +{transaction?.token_purchased}
                                  </p>
                                  <span>{transaction.tokenType}</span>
                                </td>
                                <td>
                                  <p className="m-0 font-weight-bold">
                                    {transaction?.amount_in_dollars}
                                  </p>
                                  <span>
                                    {/* USDT{" "} */}
                                    {/* <i className="fa fa-info-circle"></i> */}
                                  </span>
                                </td>
                                <td>
                                  <p className="m-0 font-weight-bold">
                                    {transaction?.amount_in_crypto}
                                  </p>
                                  <span>
                                    {transaction?.selected_crypto?.toUpperCase()}{" "}
                                    {/* <i className="fa fa-info-circle"></i> */}
                                  </span>
                                </td>
                                <td>
                                  <p className="m-0 font-weight-bold">
                                    Pay with{" "}
                                  </p>
                                  <span>
                                    {transaction?.selected_crypto?.toUpperCase()}{" "}
                                    {/* {transaction?.payment_id}{" "} */}
                                    {/* <i className="fa fa-info-circle"></i> */}
                                  </span>
                                </td>
                                <td>
                                  <p className="m-0 font-weight-bold">
                                    {transaction?.payment_id !== null
                                      ? transaction?.payment_id
                                      : 0}
                                  </p>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-end">
                                    <div className="reg-btn trans small mr-2">
                                      {transaction?.type}
                                    </div>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <i className="fa fa-ellipsis-h "></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Button
                                            // style={{backgroundColor:'transparent',color:'blue',border:"none"}}

                                            onClick={() => {
                                              navigate(
                                                `/TransactionDetails/${transaction.id}`,
                                                {
                                                  state: {
                                                    transactionObject:
                                                      transaction,
                                                  },
                                                }
                                              );
                                            }}
                                            className="view"
                                          >
                                            <i className="fa fa-eye"></i> View
                                            Details
                                          </Button>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No transactions available.
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
        <Modal
          show={referralModal}
          centered
          onHide={() => setreferralModal(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          size="xl"
          className="admin-modal"
        >
          <Modal.Body>
            <Button
              className="close-btn"
              onClick={() => setreferralModal(false)}
            >
              <i className="fa fa-close"></i>
            </Button>
            <>
              <h6>
                Referral History <i className="fa fa-angle-right"></i>{" "}
                {/* <span>UD09525</span> */}
              </h6>
              {/* <div className="text-info">
                <p className="m-0">Login with Chrome in OS X-10_15_7 via 172.70.55.74</p>
                <p className="m-0">04 Jan, 2024 12:16 AM</p>
            </div> */}
              <div className="table-container">
                <div className="table-container-inner">
                  <Table className="transaction-table">
                    <thead>
                      <tr>
                        <th>Referral Account id</th>
                        <th>Referral Email</th>
                        <th>Created Date</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading?.data ? (
                        <tr>
                          <td colSpan="6" className="text-center">
                            <Loader animation="border" />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {ReferralData?.length ? (
                            ReferralData.map((referral, index) => {
                              return (
                                <tr key={index}>
                                  <td>{referral?.referral_account_id}</td>
                                  <td>{referral?.email}</td>
                                  <td>
                                    {moment(referral?.created_at).format(
                                      "DD MMM, YYYY hh:mm A"
                                    )}
                                  </td>
                                  <td>{referral?.amount}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No Referral available.
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
        <Modal
          show={activityModal}
          centered
          onHide={() => setactivityModal(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          size="xl"
          className="admin-modal"
        >
          <Modal.Body>
            <Button
              className="close-btn"
              onClick={() => setactivityModal(false)}
            >
              <i className="fa fa-close"></i>
            </Button>
            <>
              <h6>
                Login Activities <i className="fa fa-angle-right"></i>{" "}
                {/* <span>UD09525</span> */}
              </h6>
              {/* <div className="text-info">
                <p className="m-0">Login with Chrome in OS X-10_15_7 via 172.70.55.74</p>
                <p className="m-0">04 Jan, 2024 12:16 AM</p>
            </div> */}
              <div className="table-container">
                <div className="table-container-inner">
                  <Table className="transaction-table">
                    <thead>
                      <tr>
                        <th>Browser</th>
                        <th>Device</th>
                        <th>Login Date</th>
                        <th>Ip Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading?.data ? (
                        <tr>
                          <td colSpan="6" className="text-center">
                            <Loader animation="border" />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {activityData?.length ? (
                            activityData.map((referral, index) => {
                              return (
                                <tr key={index}>
                                  <td>{referral?.browser}</td>
                                  <td>{referral?.device}</td>
                                  <td>
                                    {/* {
                                                referral?.created_at
                                               } */}
                                    {moment(referral?.created_at).format(
                                      "DD MMM, YYYY hh:mm A"
                                    )}
                                  </td>
                                  <td>{referral?.ip_address}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No Activity available.
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}
export default UserList;
