
import {LatestBlock , TotalSaleInStage,CurrentStage, OverAllSaleToken ,Promocode,SetResponse} from "./type"
export default (state, action) => {
  switch (action.type) {

      case LatestBlock:
      return {
        ...state,
        LatestBlock: action.data,
      }
      case CurrentStage:
        return {
          ...state,
          CurrentStage: action.data,
        }
        case TotalSaleInStage:
          return {
            ...state,
            TotalSaleInStage: action.data,
          }
          case OverAllSaleToken:
            return{
              ...state,
              OverAllSaleToken:action.data
            }
            case Promocode:
              return{
                ...state,
                Promocode:action.data
              }
              case Response:
              return{
                ...state,
                Response:action.data
              }
     
    default:
      return state
  }
}
