import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Container, Col, Form, Modal, Button, ModalBody, Nav } from "react-bootstrap";
import bgimg from "../Assets/img/dashboard-bg.f561b039b999495dab8a.png";
import logo from "../Assets/img/logo.png";
import 'react-circular-progressbar/dist/styles.css';
import UserContest from "../ContextAPI/UserContest";
import { emailRegex, nameRegex, passwordRegex } from "../Constants";
import { Login } from "../Api's";
import { Notifications } from "./Toast";
import { PulseLoader } from "react-spinners";
import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from "react-icons/fa";


// import { Promocode } from "../ContextAPI/type";
function Header({ setSignupShow }) {
      const { SetResponse, response } = useContext(UserContest);
       const [LoginData, setLoginData] = useState({
      email: "",
      password: ""
    })
    const navigate = useNavigate()
    const [loginShow, setloginShow] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [errors, setErrors] = useState({
      email: "",
      password: "",
      errormsg: "",
      apiresponeerror:"",
  });

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const trimmedValue = name === "email" ? value.trim() : value;
      setLoginData({
          ...LoginData,
          [name]: trimmedValue,
      });

      if (name === "email") {
          setIsValidEmail(emailRegex.test(trimmedValue));
          setErrors((prevErrors) => ({
              ...prevErrors,
              email: emailRegex.test(trimmedValue) ? "" : "Please enter a valid email address",
              errormsg:"",
              apiresponseerror:""
          }))
      } else if (name === "password") {
          const passwordValid = (trimmedValue);
          setIsPasswordValid(passwordValid);
  setErrors((prevErrors) => ({
    ...prevErrors,
    password: passwordValid
      ? ""
      : "Please enter the password ",
      errormsg:"",
      apiresponeerror:""
  }));
      }
  };

    const LoginFun = async () => {
      if (!LoginData.email || !LoginData.password) {
        Notifications('error', 'Please fill in all required fields');
        return;
    }
  

    if (!isValidEmail) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Please enter a valid email address" || "",
        }));
        return;
    }
    setIsLoading(true);
      try {
        const respone = await Login(LoginData);
        const accessToken= respone?.token
        const userdata =respone?.data
        if (!accessToken) {
          return;
      }
      
      localStorage.setItem("JsonWebToken", accessToken);
   
      localStorage.setItem("response", JSON?.stringify(respone));
        Notifications('success', 'User Signed in Successfully');
        navigate('/Dashboard');
        window.location.reload();
      } catch (error) {
        Notifications('error', 'Email or Password is incorrect');
        setIsLoading(false);
      }
    };
    useEffect(() => {
      const accessToken = localStorage.getItem("JsonWebToken");
      if (accessToken) {
          navigate("/Dashboard");
      }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  // const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  // };
 

  return (
    <>
      <header className="container-fluid">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bgimg})` }}
        ></div>
        <Modal
        size="lg"
        show={loginShow}
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Body className="log-panel">
          
          <div className="mb-3">
          <img src={logo} alt="" />
            </div>
          {/* <h3>Login</h3> */}

          <Form  >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={LoginData?.email}
                onChange={handleInputChange}
                isInvalid={!isValidEmail}
                autocomplete="off"
                className="input-email"
                placeholder="Enter Your Email"
                
              />
                  <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <div className='log-i-cntnr'>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                value={LoginData?.password}
                onChange={handleInputChange}
                className="input-password"
                placeholder="Enter your password"
                name='password'
                autoComplete="off"
              />
               
                                     
                                        {showPassword ? (
                                            <FaEye className="FaEye" onClick={() => {
                                              setShowPassword(!showPassword)}} />
                                        ) : (
                                            <FaEyeSlash  className="FaEye" onClick={() => {
                                              setShowPassword(!showPassword)}}/>
                                        )}

                                    </div>
               {/* {
                  !showPassword ?
                    <FaEyeSlash className="FaEye" onClick={() => {
                      setShowPassword(!showPassword)
                    }} /> :
                    <FaEye className="FaEye" onClick={() => {
                      setShowPassword(!showPassword)
                    }} />
                } */}
            </Form.Group>
            {errors.errormsg && (
                            <span className="errormsg " >
                    {errors.errormsg}
                  </span>
                            )}
            <div className="flex-div">
              {["checkbox"].map((type) => (
                <div key={`default-${type}`}>
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    label={`Remember me`}
                  />
                </div>
              ))}
            </div>
          </Form>
          <div className="spacer-40"></div>
          <Nav.Link 
          onClick={()=>{
            
            LoginFun()
          }} 
          disabled={isLoading}
          className="reg-btn br-radius w-100"  >
          {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Login'}
          </Nav.Link>
          <div className="spacer-40"></div>
          <ul className="btn-list justify-center">
            <li>
              <Link to="/TermsConditions">Terms & conditions</Link>
            </li>
            <li>
              <Link to="/PrivacyPolicy">Privacy Policy</Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>
    
      </header>
    </>
  );
}
export default Header;
