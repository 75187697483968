import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Row,
    Container,
    Col,
    Table,
    Button,
    Dropdown,
    Tabs,
    Tab,
    Form,
    Modal,
    Accordion,
} from "react-bootstrap";
import icontransaction from "../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../Assets/img/admin-icons/icon-eye.png";
import iconcoin from "../Assets/img/admin-icons/icon-coins-1.png";
import { Notifications } from "../Components/Toast";
import {
    AllTransationShow,
    AllUserShow,
    BlockUSer,
    DownLoaduserList,
    GetTotalToken,
    GetUSerActivity,
    ReffrealId,
    TransationOfUser,
    unBlockUSer,
} from "../Api's";
import { FilterButtonColor, formatDate } from "../Constants";
import Loader from "./Loader";
import Paginationnav from "../Components/Pagination";
import Pagination from "../Components/Pagination";
import moment from "moment";



const UserTableList = ({ index, admin, handleOpenModal, GetUSerActivityfun, setactivityModal, setreferralModal, GetReffrealId, BlockUserCall, UnBlockUserCall }) => {
    const navigate = useNavigate();

    const [token, settoken] = useState()
    useEffect(() => {
        GetTotalToken(admin.id).then((data) => {
            settoken(data?.data?.total_tokens_purchased??0)
        }).catch(() => {

        })

    }, [admin])
    return (
        <tr key={index}>
            <td className="d-flex">
                <div>
                    <p className="m-0 font-weight-bold">
                        {admin?.name}
                    </p>
                    {/* <span>UD09516</span> */}
                </div>
            </td>
            <td>
                <p className="m-0 font-weight-bold">
                    {admin?.email}
                </p>
            </td>
            <td>
                <p className="m-0 font-weight-bold">
                    { token?.toString().toUpperCase()
                              .replace(/(\D)(\d)/, "$1 $2")}
                </p>
            </td>

            <td>
                <p className="m-0 font-weight-bold">
                    <span className="data-details-info">
                        <p className="m-0 font-weight-bold">
                            {admin?.name ===
                                "WalletAddress" ? (
                                <>Wallet Connect</>
                            ) : (
                                <>
                                    <span className="clock-span">
                                        <i className="fa fa-check"></i>
                                    </span>{" "}
                                    Email
                                </>
                            )}
                        </p>
                    </span>
                </p>
            </td>




            <td>
                <div className="d-flex">
                    <div className="reg-btn trans small mr-2">
                        {admin?.isblock
                            ? "Suspended"
                            : "Active"}
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                        >
                            <i className="fa fa-ellipsis-h "></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <Button
                                    onClick={() => {
                                        navigate(
                                            `/UserListDetails/${admin.id}`,
                                            {
                                                state: {
                                                    transactionObject:
                                                        admin,
                                                },
                                            }
                                        );
                                    }}
                                    className="view"
                                >
                                    <i className="fa fa-eye"></i>{" "}
                                    View Details
                                </Button>
                            </Dropdown.Item>

                            {/* <Dropdown.Item href="#/action-1">
      <i class="fa fa-envelope-o" aria-hidden="true"></i>{" "}
      Send Email
      </Dropdown.Item> */}
                            <Dropdown.Item
                                onClick={() =>
                                    handleOpenModal(admin?.id)
                                }
                            >
                                <i className="fa fa-random"></i>{" "}
                                Transaction
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    GetUSerActivityfun(
                                        admin?.id
                                    );
                                    setactivityModal(true);
                                }}
                            >
                                <i className="fa fa-sign-out"></i>{" "}
                                Activities
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    GetReffrealId(admin?.id);
                                    setreferralModal(true);
                                }}
                            >
                                <i className="fa fa-users"></i>{" "}
                                Referrals
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => {
                                    if (!admin?.isblock) {
                                        BlockUserCall(
                                            admin?.id
                                        );
                                    } else {
                                        UnBlockUserCall(
                                            admin?.id
                                        );
                                    }
                                }}
                            >
                                <i className="fa fa-ban"></i>{" "}
                                {admin?.isblock
                                    ? "Active"
                                    : "Suspend"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </td>
        </tr>
    )


}
export default UserTableList;


