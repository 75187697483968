import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Header from "../Components/Header";
import UserContest from "../ContextAPI/UserContest";


function Home({ setShowFooter, setShowNavBar }) {
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);


  return (
    <>
      {/* Header Starts Here */}
      <Header   />
      {/* Header Starts Here */}

      {/* Partnership Starts Here */}
     </>
     
  );
}
export default Home;
