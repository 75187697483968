import axios from 'axios';
import setAuthorizationToken, { Base_Url } from '../Constants';
import { Notifications } from '../Components/Toast';
import { useNavigate } from 'react-router-dom';

const JwtToken = localStorage.getItem("JsonWebToken");

export const Signup = async (body) => {
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('accounts/Signup',body, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
      // console.log( error)

        Notifications('error',error.response.data.message)
      reject(error);
    }
  });
};


export const Login = async (body) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('accounts/LoginAdmin',body, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        // Notifications('error',"Email or Password is incorrect")
      reject(error);
    }
  });
};

export const GetCurrentStage = async (body) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get('stages/getActiveStage', {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};

export const GetCurrentStageTransactions = async (stageId) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`transactions/getCountOfTransactionOfStage?stageId=${stageId}`, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};

export const GetAllStageTransactions = async (stageId) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`transactions/getCountOfTransactionOfStage`, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};
export const GetToekenSumofWeek = async () => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    setAuthorizationToken();
    const response = await axios.get(`transactions/getTokenSumOfWeek`, {
      baseURL: Base_Url,
    });
    resolve(response?.data);
  } catch (error) {
    if (error?.response?.status === 403) {
      Notifications('error','Token expired. Please re-login.');
      localStorage.clear()
      window.location.href = '/';
    }
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

export const AmountCollected = async () => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    setAuthorizationToken();
    const response = await axios.get(`transactions/getSumOfCurrencies`, {
      baseURL: Base_Url,
    });
    resolve(response?.data);
  } catch (error) {
    if (error?.response?.status === 403) {
      Notifications('error','Token expired. Please re-login.');
      localStorage.clear()
      window.location.href = '/';
    }
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

export const SumOfUser = async () => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    setAuthorizationToken();
    const response = await axios.get(`/accounts/getSumOfUsers`, {
      baseURL: Base_Url,
    });
    resolve(response?.data);
  } catch (error) {
    if (error?.response?.status === 403) {
      Notifications('error','Token expired. Please re-login.');
      localStorage.clear()
      window.location.href = '/';
    }
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};


export const ActiveStage = async () => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    setAuthorizationToken();
    const response = await axios.get(`/stages/getActiveStage`, {
      baseURL: Base_Url,
    });
    resolve(response?.data);
  } catch (error) {
    if (error?.response?.status === 403) {
      Notifications('error','Token expired. Please re-login.');
      localStorage.clear()
      window.location.href = '/';
    }
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};


export const GraphTransation = async (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/transactions/getTransactionGraph?type=${type}`, {
        baseURL: Base_Url,
        headers: {
          'Authorization': `Bearer ${JwtToken}`,
          'Content-Type': 'application/json', 
        },
      });
      resolve(response?.data);
      
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};
export const GraphRegistration = async (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`/accounts/getUserGraph?type=${type}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const AllTransation = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`/transactions/getAllTransactions`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const AllTransationShow = async (PageSize,CurrentPage,selectedFilter,search) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`/transactions/getAllTransactions?PageSize=${PageSize}&CurrentPage=${CurrentPage}&filter=${selectedFilter}&search=${search}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const AllUserShow = async (PageSize,CurrentPage,selectedFilter,Name,obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.post(`accounts/getAllusers?PageSize=${PageSize}&CurrentPage=${CurrentPage}&filter=${selectedFilter}&Name=${Name}`,obj, 
      {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const AllStages = async (PageSize,CurrentPage) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`stages/getAllStages?PageSize=${PageSize}&CurrentPage=${CurrentPage}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};



export const GetSaleInStage = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`stages/getSaleInStage?StageID=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};
export const TotalusdByUser = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`transactions/totalusdByUser?accountId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const TransationOfUser = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`transactions/getAllTransactionOfUser?userId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const CreateIco = async (requestData) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      // const formData = new FormData();
      // for (const key in requestData) {
      //   formData.append(key, requestData[key]);
      // }
      
      const response = await axios.post(`stages/createNew`,requestData, {
        baseURL: Base_Url,
        // headers: {
        //   'Authorization': `Bearer ${JwtToken}`,
        //   'Content-Type': 'application/json', 
        // },
    
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const ReffrealId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`accounts/referralhistory?user_Id=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const GetUSerActivity = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`accounts/getUserActivity?userId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const BlockUSer = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/blockUser?UserId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const unBlockUSer = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/UnblockUser?UserId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};




export const ApproveTransaction = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/ApproveTransaction?transactionId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const RejectTrasnsaction = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/RejectTransaction?transactionId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }

      reject(error);
    }
  });
};


export const PendingTokenCount = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/getPendingTokenCount`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const ReferralTokenCount = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/getReferralTokenCount`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};



export const BonusTokenCount = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/getBonusTokenCount`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const getUserLastWeekCount = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`accounts/getUserLastWeekCount`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};



export const MarkStageActive = async (id,endDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/setStageActive?StageID=${id}&endDate=${endDate}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};



export const GetTotalToken = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const GetAllUsersbyName = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`accounts/getAllUsersbyName`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};


export const GetOffTransationToken = async (requestData) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken()
      
      const response = await axios.post(`transactions/createOflineTransaction`,requestData, {
        baseURL: Base_Url,    
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const AllTransationStatus = async (PageSize,CurrentPage,filter,search) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`/transactions/getAllTransactionsWithStaus?PageSize=${PageSize}&CurrentPage=${CurrentPage}&filter=${filter}&search=${search}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};
export const GetAllTransationNowPayment = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`https://api.nowpayments.io/v1/payment/${id}`, {
        headers: {
          "x-api-key": "J231VF3-SPYMJRX-G1KQMKJ-0W45VK4"
        }
      });
      resolve(response?.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserDetail = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`/accounts/getUserDetailByid?userId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};
export const GetTotalTokenBalance = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();

      const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};



// export const DownLoaduserList = async () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       setAuthorizationToken();
//       const response = await axios.get(`accounts/downloadUser`, {
//         baseURL: Base_Url,
//       });
     
//         // create file link in browser's memory
//         const href = URL.createObjectURL(response.data);
    
//         // create "a" HTML element with href to file & click
//         const link = document.createElement('a');
//         link.href = href;
//         link.setAttribute('download', 'UserData.xlsx'); //or any other extension
//         document.body.appendChild(link);
//         link.click();
    
//         // clean up "a" element & remove ObjectURL
//         document.body.removeChild(link);
//         URL.revokeObjectURL(href);
//         resolve(response);


//       resolve(response?.data);
//     } catch (error) {
//       if (error?.response?.status === 403) {
//         Notifications('error','Token expired. Please re-login.');
//         localStorage.clear()
//         window.location.href = '/';
//       }
//       reject(error);
//     }
//   });
// };

export const DownLoaduserList = async (address) => {
  return new Promise((resolve, reject) => {
    setAuthorizationToken();
    axios.get(`accounts/downloadUser`, {
      baseURL: Base_Url,
     responseType:'blob'
    }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'UserData.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    resolve(response);

});
  });
};


export const UpdateStageApi = async (requestData) => {
  return new Promise(async (resolve, reject) => {
    try {
        setAuthorizationToken();
        const response = await axios.post(`stages/UpdateStage`,requestData, {
        baseURL: Base_Url,  
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const DeleteStageApi = async (stageid) => {
  return new Promise(async (resolve, reject) => {
    try {
        setAuthorizationToken();
        const response = await axios.post(`stages/DeleteStage?stageId=${stageid}`, {},{
        baseURL: Base_Url,  
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const GetAllPromos = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.get(`admin/GetAllPromos`, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const AddNewPromo = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.post(`admin/AddNewPromo`,data, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};

export const UpdatePromoApi = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      setAuthorizationToken();
      const response = await axios.post(`admin/UpdatePromo`,data, {
        baseURL: Base_Url,
      });
      resolve(response?.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        Notifications('error','Token expired. Please re-login.');
        localStorage.clear()
        window.location.href = '/';
      }
      reject(error);
    }
  });
};






