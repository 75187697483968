import { ToastContainer } from 'react-toastify';
import Layout from './Layout';
import 'react-toastify/dist/ReactToastify.css'
import { useContext, useEffect, useState } from 'react';
import { GetAllStageTransactions, GetCurrentStage, GetCurrentStageTransactions } from './Api\'s';
import UserContest from './ContextAPI/UserContest';

function App() {
  const [isFormDatasaved, setIsFormDatasaved] = useState(false);


  const { SetTotalSaleInStage,
    SetCurrentStage ,SetOverAllSaleToken } = useContext(UserContest);

  useEffect(() => {
    GetCurrentStage().then((stage) => {
      SetCurrentStage(stage)
      GetCurrentStageTransactions(stage.id).then((data) => {
        SetTotalSaleInStage(data.data.total_tokens_purchased
          )
      }).catch(() => {

      })


    }).catch(() => {

    })
    GetAllStageTransactions().then((data)=>{
      SetOverAllSaleToken(data.data.total_tokens_purchased)
    }).catch(()=>{

    })
  }, [])

 
  return (
    <>
      <Layout />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme={'light'}
      />
    </>)
}
export default App;