import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Row,
    Container,
    Col,
    Table,
    Button,
    Dropdown,
    Tabs,
    Tab,
    Form,
    Modal,
    Accordion,
} from "react-bootstrap";
import icontransaction from "../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../Assets/img/admin-icons/icon-eye.png";
import iconcoin from "../Assets/img/admin-icons/icon-coins-1.png";
import { Notifications } from "../Components/Toast";
import {
    AddNewPromo,
    AllTransationShow,
    AllUserShow,
    BlockUSer,
    DownLoaduserList,
    GetAllPromos,
    GetUSerActivity,
    ReffrealId,
    TransationOfUser,
    UpdatePromoApi,
    unBlockUSer,
} from "../Api's";
import { formatDate } from "../Constants";
import Loader from "./Loader";
import { PulseLoader } from "react-spinners";

function PromoPage() {

    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [show, setShow] = useState()
    const [UpdatePromo, setUpdatePromo] = useState(false)
    const [promoData, setPromoData] = useState({
        name: '',
        bonus: '',
        activeondash: '',
        active: '',
    })
    const GetAllPromosFun = ()=>{
        GetAllPromos().then((res) => {
            setTransactions(res.data)

        }).catch(() => {

        })
    }
    useEffect(() => {
        GetAllPromosFun()
    }, [])
    const AddNewPromoFun = ()=>
    {
        AddNewPromo(promoData).then(()=>{
            GetAllPromosFun()
            setShow(false)
            Notifications('success',"Promo added successfully")


        }).catch(()=>{

        })
    }

    const UpdatePromoFun = ()=>
    {
        UpdatePromoApi(promoData).then(()=>{
            GetAllPromosFun()
            setShow(false)
            Notifications('success',"Promo updated successfully")


        }).catch(()=>{

        })
    }

    return (
        <>
            <section className="admin-panel container-fluid">
                <Row>
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12">
                                <div className="white-post">
                                    <div className="flex-div-xs align-items-center">
                                        <h4 className="color font-weight-bolder">
                                            Promo List
                                        </h4>
                                        <Button className="btnback mr-2" onClick={()=>{
                                            setUpdatePromo(false)
                                            setPromoData({})
                                            setShow(true)
                                        }}>
                                            <i className="fa fa-plus-circle"></i> Add New Promo
                                        </Button>

                                    </div>
                                    <div className="spacer-30"></div>
                                    <div className="table-container">
                                        <div className="table-container-inner">
                                            <Table className="transaction-table">
                                                <thead>
                                                    <tr>
                                                        <th>Promo</th>
                                                        <th>Bonus</th>
                                                        <th>Active on dashboard </th>
                                                        <th>Active</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading?.transactions ? (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                <Loader animation="border" />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {transactions?.length ? (
                                                                transactions.map((admin, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <p className="m-0 font-weight-bold">
                                                                                {admin?.name}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="m-0 font-weight-bold">
                                                                                {admin?.bonus}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="m-0 font-weight-bold">
                                                                                {admin?.activeondash ? 'Active' : 'Not Active'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="m-0 font-weight-bold">
                                                                                {admin?.active ? 'Active' : 'Not Active'}
                                                                            </p>
                                                                        </td>
                                                                        <td >
                                                                            <div onClick={() => {

                                                                                setShow(true)
                                                                                setPromoData(admin)
                                                                                setUpdatePromo(true)

                                                                            }} className="d-flex">
                                                                                <div
                                                                                    style={{ cursor: 'pointer' }}

                                                                                    className="reg-btn trans small mr-2">
                                                                                    Update
                                                                                </div>
                                                                            </div>
                                                                        </td>


                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="6" className="text-center">
                                                                        No Promo available.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="spacer-10"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Modal show={show} centered onHide={() => setShow(false)} animation={false} aria-labelledby="example-modal-sizes-title-lg" size="lg" className="admin-modal">
                    <Modal.Header>
                        <Modal.Title className="text-black">{UpdatePromo ? 'Update Promo' : 'Add New Promo'} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Button className="close-btn" onClick={() => setShow(false)}>
                            <i className="fa fa-close"></i>
                        </Button>

                        <Form>
                            <Form.Group as={Row} controlId="formEndDate">
                                <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                                    Name
                                </Form.Label>
                                <Col xl="7" lg="8" md="6">
                                    <Form.Control
                                        type="text"
                                        name="Name"
                                        value={promoData?.name}
                                        onChange={(e) => {
                                            setPromoData((prev) => ({
                                                ...prev,
                                                name: e.target.value


                                            }))

                                        }}
                                    // Add necessary props or event handlers as needed
                                    />
                                </Col>

                            </Form.Group>


                            <Form.Group as={Row} controlId="formEndDate">
                                <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                                    Bonus
                                </Form.Label>
                                <Col xl="7" lg="8" md="6">
                                    <Form.Control
                                        type="text"
                                        name="Bonus"

                                        value={promoData?.bonus}
                                        onChange={(e) => {
                                            setPromoData((prev) => ({
                                                ...prev,
                                                bonus: e.target.value
                                            }))

                                        }}
                                    // Add necessary props or event handlers as needed
                                    />
                                </Col>

                                <Col xl="7" lg="8" md="6">

                                    <div className="spacer-20"></div>
                                </Col>




                            </Form.Group>
                            <Form.Group as={Row} controlId="formEndDate" style={{ justifyContent: 'center', color: 'black' }}>
                                {["checkbox"].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline

                                            type={type}
                                            checked={promoData?.activeondash}
                                            value={promoData?.activeondash}
                                            onChange={(e) => {
                                                setPromoData((prev) => ({
                                                    ...prev,
                                                    activeondash: !promoData?.activeondash
                                                }))

                                            }}
                                            id={`default-${type}`}
                                            label={`Active on dashboard`}
                                        />
                                        <Form.Check
                                            inline

                                            type={type}
                                            checked={promoData?.active}
                                            value={promoData?.active}
                                            onChange={(e) => {
                                                setPromoData((prev) => ({
                                                    ...prev,
                                                    active: !promoData?.active
                                                }))

                                            }}
                                            id={`default-${type}`}
                                            label={`Active`}
                                        />

                                    </div>
                                ))}
                            </Form.Group>

                            {/* Buttons */}
                            <Form.Group as={Row} controlId="formButtons">
                                <Col xl="4" lg="4" md="6">
                                    {/* Empty column for spacing */}
                                </Col>
                                <Col xl="7" lg="8" md="6" className='m-text-center'>
                                    <Button type='button' className="cancel-btn mr-3 mt-2" variant="secondary" onClick={() => setShow(false)}>
                                        Cancel
                                    </Button>
                                    <Button disabled={isLoading} className="btnback mr-3 mt-2" variant="primary" onClick={() => {
                                         if(UpdatePromo)
                                         UpdatePromoFun()
                                        else
                                        AddNewPromoFun() }}>
                                        {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Save'}
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                </Modal>


            </section>
        </>
    );
}
export default PromoPage;
