import React, { useState } from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.scss";
import { Route, Routes } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Home from "./Pages/Home";
import Transactions from "./Pages/Transactions";
// Admin Panel
import Dashboard from "./Pages/Dashboard";
import PrivateRoutes from './Pages/Routes/PrivateRoutes';
import UserList from './Pages/UserList';
import IcoStage from './Pages/IcoStage';
import TransactionDetails from './Pages/TransactionDetails';
import UserListDetails from './Pages/UserListDetails';
import PromoPage from './Pages/PromoPage';
// Admin Panel

function NotFound() {

  return (
    <div className="pagenotfound">
      <h1>404 - Page Not Found</h1>
    </div>
  );
}


function AppRouter() {

  const [showFooter, setShowFooter] = useState(true);
  const [showNavBar, setShowNavBar] = useState(true);
  const [ SignupShow,setSignupShow] = useState(false);
  
  return (
    <>
      {showNavBar && <NavBar  setSignupShow={setSignupShow} SignupShow={SignupShow}/>}
      <Routes>
        <Route exact path="/" element={<Home setSignupShow={setSignupShow} SignupShow={SignupShow} setShowFooter={setShowFooter} setShowNavBar={setShowNavBar} />} />
        <Route path="*" element={<NotFound />} />
        {/* AdminPanel */}
        <Route element={<PrivateRoutes />}>
        <Route path="/Dashboard" element={<Dashboard setShowFooter={setShowFooter}   setShowNavBar={setShowNavBar}  />} />
        <Route path="/Transactions" element={<Transactions setShowFooter={setShowFooter}  />} />
        <Route path="/UserList" element={<UserList setShowFooter={setShowFooter}  />} />
        <Route path="/IcoStage" element={<IcoStage setShowFooter={setShowFooter}  />} />
        <Route path="/Promo" element={<PromoPage setShowFooter={setShowFooter}  />} />
        <Route path="/TransactionDetails/:id" element={<TransactionDetails setShowFooter={setShowFooter}  />} />
        <Route path="/UserListDetails/:id" element={<UserListDetails setShowFooter={setShowFooter}  />} />

        

        {/* <Route path="/details/:id"element{ <TransactionDetails setShowFooter={setShowFooter}   />} /> */}


        {/* AdminPanel */}

        {/* 404 Page */}
    
        {/* 404 Page */}
        </Route>
      </Routes>
      {/* {showFooter && <Footer />} */}
    </>
  );
}

export default AppRouter;
