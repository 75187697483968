import React, { useEffect, useState } from 'react'
import {
  Row,
  Container,
  Col,
  Table,
  Button,
  Dropdown,
  Tabs,
  Tab,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { addCommas, addCommas1, formatDate, formatDate1 } from '../Constants';
import { CircularProgressbar } from 'react-circular-progressbar';
import { DeleteStageApi, GetCurrentStageTransactions, MarkStageActive, UpdateStageApi } from '../Api\'s';
import { Notifications } from './Toast';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import Pagination from './Pagination';

const StageCard = ({ stageData, index, setModalShow, GetActiveStage }) => {
  const [dates, setdates] = useState({
    startDate: '',
    endDate: '',
  })
  const [saleInStage, setsaleInStage] = useState()
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [markAsActive, setMarkAsActive] = useState(false);
  const [id, setId] = useState(null);
  const [deleteStageModal,setdeleteStageModal] =useState(false)
  const [ActiveStageModal,setActiveStageModal]=useState(false)
  const [updateStageModal,setupdateStageModal]= useState(false)


  const [formData, setFormData] = useState(stageData);
  useEffect(() => {
    setFormData(stageData)

  }, [stageData])




  useEffect(() => {

    GetCurrentStageTransactions(stageData?.id).then((sale) => {

      setsaleInStage(sale?.data?.total_tokens_purchased)
    }).catch(() => {

    })


  }, [saleInStage])




  const handleShow = (selectedId) => {
    setId(selectedId);
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
    setdates({
      startDate: '',
      endDate: '',
    })
  }
     
  const handeldate=(e)=>{
    const {name,value}=e.target;
    
    setdates((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }


  const markAsActivefun = async () => {
    setIsLoading(true)
    const ids = id
    const endDate = dates.endDate; // Assuming endDate is in stageData
    await MarkStageActive(ids, endDate).then(() => {
      GetActiveStage(1);
      setIsLoading(false)
      Notifications('success', 'Stage updated successfully');
    }).catch(() => {
      // Handle errors
      Notifications("error", "  Stage not update")
      setIsLoading(false)
    });
  };
  const UpdateStage = () => {
    UpdateStageApi(formData).then(() => {
      GetActiveStage(1);
      setShow(false)
      Notifications('success', 'Stage updated successfully');
    }).catch(() => {

    })
  }
  const DeleteStage = () => {
    DeleteStageApi(stageData?.id).then(() => {
      GetActiveStage(1);
      setdeleteStageModal(false)
     Notifications('success', 'Stage deleted successfully');
    }).catch(() => {

    })
  }

  return (
    <>
      <Col xl="4" lg="6" md="12" sm="12" key={index}>
        <Card
          className={`ico ${stageData?.isactive === index ? "active-card" : ""
            } ${stageData?.currently_active ? "running-stage" : ""}`}

        >

          <Card.Body>
            <Card.Title>
              <div className="flex-div-xs">
                <div>
                  <h6 className="stage-title mb-1">
                    <b>STAGE NAME</b>
                    <span
                      className={`badge ${stageData.currently_active
                        ? "badge-success ml-2"
                        : "badge-danger"
                        }`}
                    >
                      {stageData.currently_active
                        ? "Running"
                        : "Expired"}
                    </span>
                  </h6>
                  <br />
                  <span>
                    {stageData?.level &&
                      stageData.level
                        .toUpperCase()
                        .replace(/(\D)(\d)/, "$1 $2")}
                  </span>
                </div>
                {(
                  <Dropdown className="trans">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <i className="fa fa-ellipsis-v "></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => markAsActivefun(stageData?.id)}>
                      Make As Active
                    </Dropdown.Item> */}
                      <Dropdown.Item onClick={() => setupdateStageModal(true )}>  Update Stage
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setdeleteStageModal(true)}>  Delete Stage
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShow(stageData?.id)}>  Active Stage
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Title>
            <Card.Text className="stageinfo">
              {/* <span>{stageData?.total_limit && (balance / stageData.total_limit * 100).toFixed(1)}%</span> */}
              <div className="img-pnl">
                <CircularProgressbar
                  value={
                    (
                      (saleInStage /
                        stageData?.total_limit) *
                      100
                    ).toFixed(3) / 100
                  }
                  maxValue={1}
                  text={`${(
                    (saleInStage /
                      stageData?.total_limit) *
                    100
                  ).toFixed(2) / 1
                    } %`}
                />
              </div>
              <div className="stage-info-txt">
                <h6 className="greycolor font-weight-normal m-0">
                  Token Issued
                </h6>
                <span className="stage-info-total">
                  <h4 className="font-weight-midium m-0">
                    {" "}
                    {stageData?.total_limit &&
                      addCommas(stageData?.total_limit)}
                  </h4>
                </span>
                <h6 className="greycolor font-weight-normal m-0">
                  Sold{" "}
                  <b className="darkgreycolor">
                    {/* {addCommas(
                                      balances[index] !== undefined
                                        ? balances[index]
                                        : 0
                                    )} */}
                    {""}
                  </b>{" "}
                  Tokens
                </h6>
                <span className="stage-info-total">
                  <h4 className="font-weight-midium m-0">
                    {" "}
                    {saleInStage &&
                      addCommas1(saleInStage)}
                  </h4>
                </span>

              </div>
            </Card.Text>
          </Card.Body>

          <ListGroup className="list-group-flush">
            <div className="baseprice1">
              <div>
                <p className="m-0 greycolor font-weight-normal">
                  Base Price
                </p>
                <b>{stageData?.price_of_token} USD</b>
              </div>
              <div>
                <p className="m-0 greycolor font-weight-normal">
                  Future Price
                </p>
                <b className=''>{stageData?.futuretokenprice}</b>
              </div>
            </div>
          </ListGroup>

          <ListGroup className="list-group-flush">
            <div className="baseprice1">
              <div>
                <p className="m-0 greycolor font-weight-normal">
                  Maximum Purchase
                </p>
                <b>{stageData?.maximumpurchase} </b>
              </div>
              <div>
                <p className="m-0 greycolor font-weight-normal">
                  Minimum Purchase
                </p>
                <b className=''>{stageData?.minimumpurchase}</b>
              </div>
            </div>
          </ListGroup>

          <Card.Body>
            <ListGroup className="list-group-flush">
              <div className="baseprice p-0">
                <div>
                  <p className="m-0 greycolor font-weight-normal">
                    Created Date
                  </p>
                  <p className="m-0">
                    {moment(stageData?.created_at).format("DD MMM, YYYY")}

                  </p>
                  <p className="m-0">
                    {moment(stageData?.created_at).format("hh:mm A")}
                  </p>

                </div>
                <div>
                  <p className="m-0 greycolor font-weight-normal">
                    End Date
                  </p>
                  {stageData?.enddate !== "undefined" ? (
                    <>
                      <p className="m-0">
                        {moment(stageData?.enddate).format("DD MMM, YYYY")}
                      </p>
                      <p className="m-0">
                        {moment(stageData?.enddate).format("hh:mm A")}
                      </p>
                    </>
                  ) : (
                    <p className="m-0">
                      End Date Not Available
                    </p>
                  )}
                </div>

              </div>
            </ListGroup>
          </Card.Body>
        </Card>

      </Col>
      <Modal show={updateStageModal} centered onHide={()=>{
        setupdateStageModal(false)
      }} animation={false} aria-labelledby="example-modal-sizes-title-lg" size="lg" className="admin-modal">
        <Modal.Header>
          <Modal.Title className="text-black">Update Stage </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form>
            {/* Name Field */}


            {/* Level Number Field */}
            <Form.Group as={Row} controlId="formLevelNumber">
              <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                Level Number
              </Form.Label>
              <Col xl="7" lg="8" md="6">
                <Form.Control
                  type="text"
                  disabled={true}
                  placeholder='Enter level number'
                  name="levelNumber"
                  value={formData.level}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      level: e.target.value,
                    }));
                  }}
                />
              </Col>
            </Form.Group>

            {/* Price of Token Field */}
            <Form.Group as={Row} controlId="formPriceOfToken">
              <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                Price of Token
              </Form.Label>
              <Col xl="7" lg="8" md="6">
                <Form.Control
                  type="text"
                  placeholder='Enter price of token'
                  name="priceOfToken"
                  value={formData.price_of_token}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      price_of_token: e.target.value,
                    }));
                  }}
                />
              </Col>
            </Form.Group>

            {/* Total Limit Field */}
            <Form.Group as={Row} controlId="formTotalLimit">
              <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                Total Limit
              </Form.Label>
              <Col xl="7" lg="8" md="6">
                <Form.Control
                  type="text"
                  placeholder='Enter total limit'
                  name="totalLimit"
                  value={formData.total_limit}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      total_limit: e.target.value,
                    }));
                  }}
                // Add necessary props or event handlers as needed
                />
              </Col>
            </Form.Group>

            {/* Future Token Price Field */}
            <Form.Group as={Row} controlId="formFutureTokenPrice">
              <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                Future Token Price
              </Form.Label>
              <Col xl="7" lg="8" md="6">
                <Form.Control
                  type="text"
                  placeholder='Enter future token price'
                  name="futureTokenPrice"
                  value={formData.futuretokenprice}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      futuretokenprice: e.target.value,
                    }));
                  }}
                // Add necessary props or event handlers as needed
                />
              </Col>
            </Form.Group>

            {/* Minimum Purchase Field */}
            <Form.Group as={Row} controlId="formMinimumPurchase">
              <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                Minimum Purchase
              </Form.Label>
              <Col xl="7" lg="8" md="6">
                <Form.Control
                  type="text"
                  placeholder='Enter minimum purchase'
                  name="minimumPurchase"
                  value={formData.minimumpurchase}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      minimumpurchase: e.target.value,
                    }));
                  }}
                // Add necessary props or event handlers as needed
                />
              </Col>
            </Form.Group>



            {/* Maximum Purchase Field */}
            <Form.Group as={Row} controlId="formMaximumPurchase">
              <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                Maximum Purchase
              </Form.Label>
              <Col xl="7" lg="8" md="6">
                <Form.Control
                  type="text"
                  placeholder='Enter maximum purchase'
                  name="maximumPurchase"
                  value={formData.maximumpurchase}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      maximumpurchase: e.target.value,
                    }));
                  }}
                // Add necessary props or event handlers as needed
                />
              </Col>
            </Form.Group>

            {/* Buttons */}
            <Form.Group as={Row} controlId="formButtons">
              <Col xl="4" lg="4" md="6">
                {/* Empty column for spacing */}
              </Col>
              <Col xl="7" lg="8" md="6" className='m-text-center'>
                <Button type='button' className="cancel-btn mr-3 mt-2" variant="secondary" onClick={()=>{
                
                    setupdateStageModal(false)
              
                }}>
                  Cancel
                </Button>
                <Button disabled={isLoading} className="btnback mr-3 mt-2" variant="primary" onClick={UpdateStage}>
                  {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Save'}
                </Button>
              </Col>
            </Form.Group>
          </Form>

        </Modal.Body>
      </Modal>
      <Modal show={deleteStageModal} centered onHide={()=>setdeleteStageModal(false)} animation={false} aria-labelledby="example-modal-sizes-title-lg" size="lg" className="admin-modal">
        <Modal.Header>
          <Modal.Title className="text-black">Delete Stage </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <h4 className='textCenterBlack'>Are you sure you want to delete the stage?</h4>
          <Form>
        
            {/* Buttons */}
            <Form.Group as={Row} controlId="formButtons">
              <Col xl="4" lg="4" md="6">
                {/* Empty column for spacing */}
              </Col>
              <Col xl="7" lg="8" md="6" className='m-text-center'>
                <Button type='button' className="cancel-btn mr-3 mt-2" variant="secondary" onClick={()=>setdeleteStageModal(false)}>
                  Cancel
                </Button>
                <Button disabled={isLoading} className="btnback mr-3 mt-2" variant="primary" onClick={DeleteStage}>
                  {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Delete'}
                </Button>
              </Col>
            </Form.Group>
          </Form>

        </Modal.Body>
      </Modal>
      <Modal show={show} centered onHide={()=>handleClose} animation={false} aria-labelledby="example-modal-sizes-title-lg" size="lg" className="admin-modal">  
                <Modal.Header>
                    <Modal.Title className="text-black">Active Stage </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <Button className="close-btn" onClick={handleClose}>
            <i className="fa fa-close"></i>
        </Button>
           
                <Form>
     

          
            <Form.Group as={Row} controlId="formEndDate">
                <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                    End Date
                </Form.Label>
                <Col xl="7" lg="8" md="6">
                    <Form.Control
                     type="datetime-local"
                        name="endDate"
                        value={dates.endDate}
                        onChange={handeldate}
                        // Add necessary props or event handlers as needed
                    />
                </Col>
                
            </Form.Group>

            {/* Buttons */}
            <Form.Group as={Row} controlId="formButtons">
                <Col xl="4" lg="4" md="6">
                    {/* Empty column for spacing */}
                </Col>
                <Col xl="7" lg="8" md="6" className='m-text-center'>
                    <Button type='button' className="cancel-btn mr-3 mt-2" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button  disabled={isLoading} className="btnback mr-3 mt-2" variant="primary"onClick={markAsActivefun}> 
                    {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Save'}
                    </Button>
                </Col>
            </Form.Group>
        </Form>

                </Modal.Body>
            </Modal>

 
    </>




  )
}

export default StageCard
