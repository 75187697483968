import React, { useReducer,useRef } from 'react'
import playerContext from './UserContest'
import playerReducer from './UserReducer'
import {
   
  CurrentStage,
  LatestBlock,
  TotalSaleInStage,
  OverAllSaleToken,
  Promocode,
  Response,
  } from './type'
const UserState = (props) => {
  const initialState = {
    LatestBlocks: 'Data',
    CurrentStage: {},
    TotalSaleInStage :0,
    OverAllSaleToken:0,
    Promocode:"",
    Response: null,
  }
  const [state, dispatch] = useReducer(playerReducer, initialState)
  const SetResponse = (data) =>
    dispatch({ type: Response, data:  data })
    const SetLatestBlock = (data) =>
    dispatch({ type: LatestBlock, data:  data })
   const SetCurrentStage= (data) =>
    dispatch({ type: CurrentStage, data:  data })
    
    const SetTotalSaleInStage= (data) =>
    dispatch({ type: TotalSaleInStage, data:  data })
        
    const SetOverAllSaleToken= (data) =>
    dispatch({ type: OverAllSaleToken, data:  data })

    const SetPromocode = (data) =>
    dispatch({ type: Promocode, data:  data })
    
  return (
    <playerContext.Provider
      value={{
        LatestBlocks: state.LatestBlock,
        CurrentStage:state.CurrentStage,
        TotalSaleInStage:state.TotalSaleInStage,
        OverAllSaleToken:state.OverAllSaleToken,
        Promocode:state.Promocode,
        Response:state.Response,
        SetLatestBlock,
        SetTotalSaleInStage,
        SetCurrentStage,
        SetOverAllSaleToken,
        SetPromocode,
        SetResponse,
      }}
    >
      {props.children}
    </playerContext.Provider>
  )
}

export default UserState


















