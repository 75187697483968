import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { AllStages, GetTotalToken, GetTotalTokenBalance, TotalusdByUser, TransationOfUser } from "../Api's";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../Constants";

const UserListDetails = () => {
  let transactionId = window.location.pathname?.split("/")[2];
  const [transactions, setTransactions] = useState();
  const [allstages, setallstages] = useState()

  const navigate = useNavigate();
  const [totalContributed, settotalContributed] = useState()
  const location = useLocation();
  const { transactionObject } = location.state || {};
  useEffect(() => {
    AllStages().then((data) => {
      setallstages(data?.data)
    }).catch(() => {

    })

  }, [transactionObject])

  useEffect(() => {
    GetTotalTokenBalance(transactionId).then((data) => {
      console.log(data.data.total_tokens_purchased,'balance')

    }).catch(()=>{})
    GetTotalToken(transactionId).then((data) => {
      setTransactions(data?.data)
    }).catch(() => {

    })
    TotalusdByUser(transactionId).then((data) => {

      settotalContributed(data?.data?.totalraised)
    }).catch(() => {

    })
  }, [])
  return (
    <section className="admin-panel container-fluid">
      <Row>
        <Container>
          <Row>
            <Col xl="12" lg="12" md="12">
              <div className="white-post">
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <div className="flex-div-xs align-items-center">
                      <h4 className="color font-weight-bolder">
                        User Details {" "}
                        <span className="getid">
                          {" "}
                          {">"}
                        </span>
                      </h4>

                      <Button
                        className="btnback mr-2"
                        onClick={() => navigate("/UserList")}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="spacer-30"></div>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <Card>
                      <Card.Body className="p-0">
                        <div className="data-details">
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">
                                Token Balance
                              </span>
                              <span className="data-details-info">
                                {transactions?.total_tokens_purchased ?? 0}
                              </span>
                            </Card.Title>
                          </div>
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">
                                Contributed
                              </span>
                              <span className="data-details-info">
                                {totalContributed ?? 0}  USD
                              </span>
                            </Card.Title>
                          </div>
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">
                                User Status

                              </span>
                              <span className={transactionObject?.isblock ? "badge-danger" : "color-green"}>
  {transactionObject?.isblock ? "Suspended" : "Active"}
</span>

                            </Card.Title>
                          </div>

                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">

                              </span>
                              <span className="data-details-info">
                                <p className="m-0 font-weight-bold">
                                  {transactionObject?.name === "WalletAddress" ? (
                                    <>
                                      Wallet Connect
                                    </>
                                  ) : (
                                    <>
                                      <span className="clock-span">
                                        <i className="fa fa-check"></i>
                                      </span>{" "}
                                      Email
                                    </>
                                  )}
                                </p>
                              </span>

                            </Card.Title>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <h6 className="card-sub-title ">USER INFORMATION </h6>
                    <ul className="transaction-container">
                      <li>
                        <h6>Full Name</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            {transactionObject?.name}
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Email Address</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            {transactionObject?.email}
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Mobile Number</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            {transactionObject?.phonenumber}
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Date of Birth</h6>
                        <div className="transaction-txt-cntnr">
                          <p>{transactionObject?.date_of_birth !== null ? transactionObject?.date_of_birth : '---'}</p>

                          {/* <p>MSK7enop1EqJxeSEWxF8FWLYbrWwwvVEZi</p> */}
                        </div>
                      </li>
                      <li>
                        <h6>Nationality</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <p>{transactionObject?.nationality !== null ? transactionObject?.nationality : '---'}</p>
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Wallet Address</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <p>{transactionObject?.wallet_address !== null ? transactionObject?.wallet_address : '---'}</p>

                          </p>
                        </div>
                      </li>

                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <h6 className="card-sub-title ">MORE INFORMATION</h6>
                    <ul className="transaction-container">
                      <li>
                        <h6>Joining Date</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            {
                              formatDate(
                                transactionObject?.created_at
                              ).split(", ")[0]
                            }
                            ,{""}
                            {
                              formatDate(
                                transactionObject?.created_at
                              ).split(", ")[1]
                            }{" "}
                            {""}
                            {/* {
                                                    formatDate(
                                                        transactionObject?.created_at
                                                    ).split(", ")[2]
                                                  } */}
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Referred By</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <p>{transactionObject?.referral_link !== null ? transactionObject?.referral_link : ' Join without referral!'}</p>

                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Reg Method</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                          {transactionObject?.name === "WalletAddress" ? (
                                    <>
                                      Wallet Connect
                                    </>
                                  ) : (
                                    <>
                                      Email
                                    </>
                                  )}
                          </p>
                          {/* <p><span><i className="fa fa-info-circle"></i> 1F1N6p4urc2QgzadQdMYg9wJV7acauyZjm</span></p> */}
                        </div>
                      </li>
                      <li>
                        <h6>2FA Enabled</h6>
                        <div className="transaction-txt-cntnr">
                          <p>{transactionObject?.twofactor ? 'Yes' : 'No'}</p>

                        </div>
                      </li>
                      <li>
                        <h6>Last Login</h6>
                        <div className="transaction-txt-cntnr">
                          <p>---</p>
                          {/* <p><span>(0 + 376)</span></p> */}
                        </div>
                      </li>

                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </section>
  );
};

export default UserListDetails;
